const labels = {
  candy: {
    serial_number: "Numer seryjny urządzenia (16 cyfr)",
  },
  hoover: {
    serial_number: "Numer seryjny urządzenia (16 cyfr)",
  },
  haier: {
    serial_number: "Numer seryjny urządzenia (20 znaków)",
  },
};

export default labels[process.env.REACT_APP_BRAND];
